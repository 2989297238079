import React from 'react';
import { Grid, Box, useMediaQuery,useTheme as T} from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RewardsMobileIcon from '../assets/icons/RewardsMobileIcon';
import RewardsIcon from '../assets/icons/RewardsIcon';
import { useTheme } from "../assets/themeContext";
import SmileEmojiIcon from '../assets/icons/SmileEmojiIcon';
import images from "../utils/Images";

const Rewards: React.FC = () => {
  const { theme } = useTheme();
  const t= T();
  const isXsScreen = useMediaQuery(t.breakpoints.down('sm'));
  const matchesSM = useMediaQuery(t.breakpoints.up('sm'));

  const navigateTo = (url: string) => {
    window.location.replace(url);
  };

  return (
    <div style={{ flexGrow: 1, padding: '20px', }}>
      <Grid container spacing={3} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} sm={6}>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isXsScreen ? null :<SmileEmojiIcon />}
        </Grid>
          <Typography variant="body2" gutterBottom sx={{fontFamily:"Inter"}}>
            WHATS GREAT ABOUT OUR REWARDS?
          </Typography>
          <Grid item lg={9} sx={{ display: 'flex', flexDirection: 'row'}}>
          <Typography variant="h2" gutterBottom sx={{color:theme.palette.secondary.main, fontSize: matchesSM ? '48px':'24px',fontWeight:600, fontFamily:'Inter'}}>
            Friends Make You <br /> 
         <Grid container sx={{ flexDirection: 'row' }}>
            <Grid>
                 Money   
            </Grid> 
                <img src={images.StarEyes.src} alt={images.StarEyes.alt} style={{ maxWidth: '15%'}} />
            </Grid>
          </Typography>
          </Grid>
          <Typography gutterBottom sx={{ color:theme.palette.secondary.light,fontFamily:'Inter', fontSize: matchesSM ? '20px':'16px'}} >
             Yes, earn ₹0.5 for every $1 your reffaral sends.<br />
             Unlimited rewards from every referral you make.<br />
          <Grid container sx={{ flexDirection: 'row' }}>
          <Grid>
             What you are waiting for ?
          </Grid> 
        </Grid>
        </Typography>
          <Grid sx={{ paddingTop: '18px'}}>
          <Button variant="outlined" sx={{color:'#1F1F1F', border:'1px solid rgba(31,31,31,1)', padding:'12px', borderRadius:'12px'}} 
            onClick={() => navigateTo("https://app-dev.aptremit.io/SignUp")}
          >
            <label style={{fontFamily:'Inter', fontWeight:'500', fontSize:'20px', lineHeight:'24.2px', textTransform: "none"}}>
            Get Started 
            </label>
          </Button>
          </Grid>
          
        </Grid>
        {isXsScreen ? (

       <Grid item xs={12}>
         
         <Box sx={{ display: 'flex', justifyContent: 'center',  background: 'linear-gradient(60deg, #EDE0FE 0%, #F8F9FD 100%)', }}>
          <RewardsMobileIcon/>
         </Box>
         </Grid>
         ) : (
         <Grid item xs={6} >
           <Box sx={{ display: 'flex', paddingLeft: '10px' , top: '20px' ,justifyContent: 'center' ,background: 'linear-gradient(60deg, #EDE0FE 0%, #F8F9FD 100%)' }}>
           
             <RewardsIcon/>
            
           </Box>
        </Grid>
        )}
        </Grid>
     
    </div>
  );
}

export default Rewards;