import React, { useState } from 'react';
import { AppBar, Toolbar,Button, IconButton, useMediaQuery, Box, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from "../assets/themeContext";
import ARLogoIcon from '../assets/icons/ARLogoIcon';

const Header: React.FC = () => {
  const { theme } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  
  const navigateTo = (url: string) => {
    window.location.replace(url);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#fff' }} elevation={0}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        <ARLogoIcon style={{ marginLeft: theme.spacing(-5) }} />
        </Box>
        
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              sx={{
                color: theme.palette.secondary.main,
                marginRight: theme.spacing(2),
              }}
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={isMenuOpen}
              onClose={handleClose}
              sx={{
                '& .MuiPaper-root': {
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  borderRadius: theme.shape.borderRadius,
                }
              }}
            >
              <MenuItem onClick={() => navigateTo("https://app-dev.aptremit.io/login")}>Login</MenuItem>
              <MenuItem  onClick={() => navigateTo("https://app-dev.aptremit.io/SignUp")}>Get Started</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button 
              sx={{ color: theme.palette.primary.main, fontSize: '16px', fontWeight: 600, pr: 6 }} 
              onClick={() => navigateTo("https://app-dev.aptremit.io/login")}
            >
                Login
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark, // Adjust for hover state
                }
              }}
              onClick={() => navigateTo("https://app-dev.aptremit.io/SignUp")}
            >
              Get Started
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
