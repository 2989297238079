import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Box,
  Button,
  useTheme as T,
  useMediaQuery,
} from "@mui/material";
import images from "../utils/Images";
import { useTheme } from "../assets/themeContext";
import "../utils/Fonts.css";
import getExchangeRate from "../services/ExchangeRateService";

type CurrencyFieldProps = {
  label: string;
  value: string | number;
  startAdornmentImgSrc: string;
  endAdornmentText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
};

const CurrencyField: React.FC<CurrencyFieldProps> = ({
  label,
  value,
  onChange,
  startAdornmentImgSrc,
  endAdornmentText,
  readOnly = false,
}) => (
  <TextField
    label=""
    fullWidth
    type="number"
    variant="outlined"
    value={value}
    onChange={onChange}
    InputProps={{
      startAdornment: (
        <img
          src={startAdornmentImgSrc}
          alt={label}
          style={{ width: "40px", paddingRight: "10px" }}
        />
      ),
      endAdornment: <label className="inter500">{endAdornmentText}</label>,
      style: { borderRadius: "12px" },
    }}
    disabled={readOnly}
  />
);

const HomeScreen: React.FC = () => {
  const [usdAmount, setUsdAmount] = useState<string>("");
  const [inrAmount, setInrAmount] = useState<string>("");
  const [conversionRate, setConversionRate] = useState<number | null>(null);
  const [ourconversionRate, setourConversionRate] = useState<number | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  //const conversionRate = 82; // Example conversion rate
  const { theme } = useTheme();
  const t = T();
  const isSmallScreen = useMediaQuery(t.breakpoints.down("sm"));
  useEffect(() => {
    const fetchExchangeRate = async () => {
      setLoading(true);
      try {
        const data = await getExchangeRate();
        setConversionRate(data.rate);
      } catch (err) {
        setError("Failed to fetch exchange rate");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchExchangeRate();
  }, []);

  useEffect(() => {
    if (conversionRate) {
      setourConversionRate(conversionRate + 1);
    }
  }, [conversionRate]);
  const handleUsdInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const usdValue = event.target.value;
    setUsdAmount(usdValue);
    if (conversionRate !== null && ourconversionRate !== null) {
      const inrValue = parseFloat(usdValue) * ourconversionRate;
      setInrAmount(isNaN(inrValue) ? "" : inrValue.toFixed(2));
    }
  };

  const handleInrInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inrValue = event.target.value;
    setInrAmount(inrValue);
    if (conversionRate !== null && ourconversionRate !== null) {
      const usdValue = parseFloat(inrValue) / ourconversionRate;
      setUsdAmount(isNaN(usdValue) ? "" : usdValue.toFixed(2));
    }
  };

  const navigateTo = (url: string) => {
    window.location.replace(url);
  };
  return (
    <Grid
      container
      spacing={0}
      justifyContent="center"
      sx={{ padding: isSmallScreen ? 0 : 3 }}
    >
      <Card
        sx={{
          width: { xs: "100%" },
          backgroundColor: "#fff",
          boxShadow: "10px 0px 50px rgba(214, 182, 255, 0.8)",
          borderRadius: 5,
        }}
      >
        <CardContent>
          {/* change to label */}
          <label
            style={{ fontSize: "17.5px", fontWeight: "600", color: "#1F1F1F" }}
          >
            Conversion
          </label>
          <Box
            sx={{
              boxShadow: "0px 11.615px 40px rgba(7, 13, 27, 0.05)",
              padding: 2,
              borderRadius: 5,
              marginTop: "10px",
            }}
          >
            <Grid mb={1}>
              <label
                style={{
                  fontSize: "17.5px",
                  fontWeight: "500",
                  color: "#1F1F1F",
                  opacity: 0.75,
                }}
              >
                You Send
              </label>
            </Grid>
            <CurrencyField
              label="CAD"
              value={usdAmount}
              onChange={handleUsdInputChange}
              startAdornmentImgSrc={images.cadFlag.src}
              endAdornmentText="CAD"
            />
          </Box>
          <Box
            sx={{
              boxShadow: "0px 11.615px 40px rgba(7, 13, 27, 0.05)",
              padding: 2,
              borderRadius: 5,
            }}
          >
            <Grid mb={1}>
              <label
                style={{
                  fontSize: "17.5px",
                  fontWeight: "500",
                  color: "#1F1F1F",
                  opacity: 0.75,
                }}
              >
                They Receive
              </label>
            </Grid>
            <CurrencyField
              label="INR"
              value={inrAmount}
              onChange={handleInrInputChange}
              startAdornmentImgSrc={images.indianFlag.src}
              endAdornmentText="INR"
            />
          </Box>
          {loading ? (
            <Box textAlign={"center"}>
              <label style={{ marginTop: 2 }}>Loading...</label>
            </Box>
          ) : error ? (
            <label style={{ marginTop: 2, color: "error.main" }}>{error}</label>
          ) : (
            <Box sx={{ marginTop: 2, ml: 2, mb: 2 }}>
              <Box mb={1}>
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "550",
                    color: "#6A6A6A",
                    paddingBottom: "10px",
                  }}
                >
                  Breakdown
                </label>
              </Box>
              <Grid container spacing={0}>
                {conversionRate !== null && (
                  <Grid item xs={12}>
                    <label
                      style={{
                        fontSize: "12px",
                        fontWeight: "550",
                        color: "#000",
                        lineHeight: "16.8px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Exchange price:</span>
                      <span>{conversionRate} INR</span>
                    </label>
                  </Grid>
                )}

                {conversionRate !== null && (
                  <Grid item xs={12}>
                    <label
                      style={{
                        fontSize: "12px",
                        fontWeight: "550",
                        color: "#000",
                        lineHeight: "16.8px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Our price:</span>
                      <span>
                        <span style={{ textDecoration: "line-through" }}>
                          {conversionRate}
                        </span>{" "}
                        {conversionRate + 1} INR
                      </span>
                    </label>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <label
                    style={{
                      fontSize: "12px",
                      fontWeight: "550",
                      color: "#000",
                      lineHeight: "16.8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Transfer Fee:</span>
                    <span>Free</span>
                  </label>
                </Grid>

                <Grid item xs={12}>
                  <label
                    style={{
                      fontSize: "12px",
                      fontWeight: "550",
                      color: "#000",
                      lineHeight: "16.8px",
                    }}
                  >
                    <span>
                      *We donate 0.5 INR per CAD towards girl child education
                    </span>
                  </label>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box sx={{ marginTop: 3, textAlign: "center" }}>
            <Button
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: "20px",
                fontSize: "14px",
                fontWeight: "500",
                color: "#fff",
              }}
              variant="contained"
              onClick={() => navigateTo("https://app-dev.aptremit.io/login")}
            >
              Transfer Now
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default HomeScreen;
